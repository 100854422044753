<template>
    <div>
        <button id="playbutton"></button>

        <section class="dne-token-purchase">
            <div class="anc" id="dne-token"></div>

            <div class="container">
                
                <div class="load load-200" v-if="load"></div>
                
                <!-- <div class="row dne-token-purchase-cont" v-if="($store.state.phaseCurrent.phase_type == 'Private' || $store.state.phaseCurrent.phase_type == 'Public') && !load">
                    <div class="col-12 col-lg-8">
                        <RegisterPhase />
                    </div>

                    <div class="col-12 col-lg-4">
                        <Countdown v-if="$store.state.phaseCurrent.phase_type == 'Private' || $store.state.phaseCurrent.phase_type == 'Public'" />
                    </div>

                    <div class="col-12 phases">
                        <Phases />
                    </div>
                </div> -->

                <div class="row dne-token-purchase-cont" v-if="!load">
                    <div class="col-12">
                        <RegisterPhase :dataSource="$store.state.phaseCurrent.phase_type" />
                    </div>
                </div>
            </div>

            <video id="dneVideo" class="bg-video" autoplay="autoplay" loop="true" muted defaultmuted playsinline>
                <source src="https://cdn.dneecosystem.com/dne-token-phases-desktop.mp4" type="video/mp4">
                <source src="https://cdn.dneecosystem.com/dne-token-phases-desktop.webm" type="video/webm">
            </video>
        </section>

        <!-- <div class="phases phases-mobile" v-if="$store.state.phaseCurrent.phase_type == 'Private' || $store.state.phaseCurrent.phase_type == 'Public'">
            <Phases />
        </div> -->
    </div>
</template>


<script>
    import RegisterPhase from '@/components/RegisterPhase.vue';
    // import Countdown from '@/components/Countdown.vue';
    // import Phases from '@/components/Phases.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    // import apiInternational from '@/config/apiInternational.js'

    export default {
        components: {
            RegisterPhase,
            // Countdown,
            // Phases
        },

        data(){
            return{
                flagSelected: "en",
                load: true
            }
        },

        async mounted() {
            setTimeout(() => {
                this.load = false
            }, 1500);

            // const resumePhase= await apiInternational.get('api/v2/ico/phase/')
            // console.log(resumePhase.data)
            // this.dataPhaseCurrent = resumePhase.data.filter(curr => curr.status_extra === "phase_current")[0]
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    #playbutton{
        display: none;
    }

    .dne-token-purchase{
        @include bgBlueLinear;
        position: relative;
        width: 100%;
        overflow: hidden;

        @media (max-width: 576px){
            height: 420px;
        }
        
        @media (min-width: 576px) and (max-width: 992px){
            height: 480px;
        }

        @media (min-width: 992px) and (max-width: 1200px){
            height: 500px;
        }

        @media (min-width: 1200px){
            height: 660px;
        }


        &:after{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 10;
            left: 0;
            top: 0;
            background: rgba($color: #000000, $alpha: 0.4);
        }

        .row{
            justify-content: space-between;
        }
        
        .container{

            // @media (min-width: 992px){
                position: absolute;
                z-index: 20;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            // }
        }

        .dne-token-purchase-cont{       
            align-items: flex-end;
            justify-content: center;

            .phases{
                padding: 0;

                @media (max-width: 1200px){
                    display: none;
                }
            }
        }
        
        .bg-video{
            display: block;
            min-width: 100%;
            
            @media (max-width: 576px){
                height: 420px;
            }
            
            @media (min-width: 576px) and (max-width: 768px){
                height: 480px;
            }
        
            @media (min-width: 768px) and (max-width: 992px){
                height: 560px;
            }
        
            @media (min-width: 992px){
                width: 100%;
            }
        }
    }


    // PHASES MOBILE
    .phases-mobile{
        @include bgBlueLinear;
        @include paddingSection;

        @media (min-width: 1200px){
            display: none;
        }
    }
</style>