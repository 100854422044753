<template>
    <div class="home compliance-page">
        <div id="dne"></div>
        <Header />

        <section class="compliance">

            <div class="load load-white load-200" v-if="!showContent"></div>

            <div class="container" v-if="showContent && errorsGet.dataAccount != false">
                <div class="box-error">
                    <h3 class="title-secondary">
                        {{ getMyLanguage("box-error", "title-error") }}
                    </h3>

                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>

                    <div class="cod-error">
                        Cód.: {{errorsGet.dataAccount}}
                    </div>
                </div>
            </div>

            <div class="container" v-if="showContent && !errorsGet.dataAccount">
                <h2 class="title-secondary">
                    {{getMyLanguage("compliance","compliance.title")}}
                </h2>
                
                <div class="box-compliance">
                    <div class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg." + dataAccount.backOfficeStatus)'></div>

                    <!-- <div v-if='dataAccount.backOfficeStatus === "Pending" && !dataAccount.documentsValidated' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.Pending")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "Pending" && dataAccount.documentsValidated' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.waitingAccept")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "None"' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.None")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "Review"' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.Review")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "Accepted"' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.Accepted")'></div>
                    <div v-if='dataAccount.backOfficeStatus === "Rejected"' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg.Rejected")'></div> -->

                    <!-- PERSONAL INFORMATION -->
                    <div class="box-registration" v-for="(listPartner, index) in dataAccount.partners" :key="index">
                        <div class="formBox">
                            <h3 class="subtitle">
                                {{getMyLanguage("compliance","compliance.company.title-partner")}}
                            </h3>

                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <label class="formFullName">
                                        {{getMyLanguage("compliance","compliance.lbl.fullName")}}
                                        <span class="form formDisabled">{{listPartner.fullName}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formYourEmail">
                                        {{getMyLanguage("compliance","compliance.lbl.email")}}
                                        <span class="form formDisabled">{{listPartner.email}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formPhone">
                                        {{getMyLanguage("compliance","compliance.lbl.phone")}}
                                        <span class="form formDisabled">{{listPartner.phoneNumber}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formResidenceCountry">
                                        {{getMyLanguage("compliance","compliance.lbl.country")}}
                                        <span class="form formDisabled" v-if="showCountry">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${dataAccount.countryFlag}/flags`" class="flag" v-if="dataAccount.countryFlag != false && dataAccount.countryFlag != null">
                                            {{ countryTranslate(dataCountry.filter(country => country.Name == listPartner.country)[0].IsoCode) }}
                                        </span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formPersonDOB">
                                        {{getMyLanguage("compliance","compliance.lbl.dob")}}
                                        <span class="form formDisabled">{{dateTime(listPartner.dob)}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="formTaxNumber" :class="{required: errorsValidate.taxNumber}">
                                        {{getMyLanguage("compliance","compliance.lbl.taxNumber")}}
                                        <span class="form formDisabled">{{listPartner.taxNumber}}</span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="formStatusKYC" :class="{active: listPartner.kyc, pending: !listPartner.kyc}">
                                        {{getMyLanguage("compliance","compliance.lbl.kyc")}}
                                        
                                        <span class="form formDisabled statusKYC" v-if="listPartner.kyc">
                                            {{getMyLanguage("compliance","compliance.lbl.kyc.active")}}
                                        </span>

                                        <span class="form formDisabled statusKYC" v-if="!listPartner.kyc">
                                            {{getMyLanguage("compliance","compliance.lbl.kyc.pending")}}
                                        </span>
                                        
                                        <small v-if="!dataAccount.partnersValidated" v-html='getMyLanguage("compliance","compliance.msg.kyc.incomplete")'></small>
                                        <small v-if="dataAccount.partnersValidated" v-html='getMyLanguage("compliance","compliance.msg.kyc.complete")'></small>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- COMPANY DATA -->
                    <div class="box-registration address-compliance">
                        <div class="formBox">

                            <h3 class="subtitle">
                                {{getMyLanguage("compliance","compliance.company.title")}}
                            </h3>

                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.companyName")}}
                                        <input type="text" class="form" :class="{error: errorsValidate.fullName}" v-model="dataCompliance.fullName">
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.email")}}
                                        <span class="form formDisabled">{{dataAccount.company.email}}</span>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.country")}}
                                        <span class="form formDisabled" v-if="showCountry">{{ countryTranslate(dataCountry.filter(country => country.Name == dataAccount.company.countryName)[0].IsoCode) }}</span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.phone")}}
                                        <span class="form formDisabled">{{dataAccount.company.phoneNumber}}</span>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label class="noIcon">
                                        {{getMyLanguage("compliance","compliance.lbl.taxNumberCompany")}}
                                        <input type="text" class="form" :class="{error: errorsValidate.taxNumber}" v-model="dataCompliance.taxNumber">
                                    </label>
                                </div>

                                <div class="col-12">
                                    <label class="observations-data noIcon">
                                        {{getMyLanguage("compliance","compliance.company.observations")}}
                                        <span class="cont">{{dataCompliance.observations.length}}/250</span>
                                        <textarea class="form" v-model="dataCompliance.observations" maxlength="250" :placeholder='getMyLanguage("compliance","compliance.placeholder.additional-address")'></textarea>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- DOCUMENTS -->
                    <div class="box-registration box-documents">
                        <div id="Docs"></div>
                        <div class="formBox">

                            <h3 class="subtitle">
                                {{getMyLanguage("compliance","compliance.company.title-docs")}}
                            </h3>

                            <p class="obs-mandatory">
                                {{getMyLanguage("compliance","compliance.company.obs-mandatory")}}
                            </p>

                            <div class="list-docs" v-for="(listDocs, index) in dataAccount.documents" :key="index">
                                <div class="doc" v-if='listDocs.accountDocumentMandatory || listDocs.attachmentType != "none"' :class="listDocs.accountDocumentStatusExtra">
                                <!-- <div class="doc" :class="listDocs.accountDocumentStatusExtra"> -->
                                    <div class="doc-inf" :class="{center: listDocs.attachment === null}">
                                        <div class="doc-name">
                                            <small :class="{mandatory: listDocs.accountDocumentMandatory}">
                                                {{ getMyLanguage("compliance","compliance.document-type." + listDocs.accountDocumentTypeCode) }}
                                            </small>

                                            <strong v-if='listDocs.attachmentType === "form_input"'>{{listDocs.accountDocumentNumber}}</strong>
                                            
                                            <a :href="`https://dne-international-api.azurewebsites.net/api/v1/sales/compliance/kyc/document/${listDocs.accountDocumentId}`" target="_blank">
                                                {{listDocs.fileName}}
                                            </a>
                                        </div>
                                        
                                        <span class="status" :class="listDocs.accountDocumentStatusExtra">
                                            {{ getMyLanguage("compliance","compliance.document-status." + listDocs.accountDocumentStatusExtra) }}
                                        </span>

                                        <!-- <a class="view"></a> -->
                                    </div>

                                    <div class="doc-observations" v-if="listDocs.observations != null">
                                        {{listDocs.observations}}
                                    </div>
                                </div>
                            </div>


                            <!-- UPLOAD DOCS -->
                            <div class="upload-docs" v-if='(dataAccount.backOfficeStatus === "None" || (dataAccount.backOfficeStatus === "Pending" && !dataAccount.documentsValidated))'>
                                <p>
                                    <strong>{{getMyLanguage("compliance","compliance.company.upload-docs")}}</strong>
                                </p>

                                <select class="form" v-model="dataSendDoc.accountDocumentTypeCode">
                                    <option value="" selected disabled hidden>
                                        {{getMyLanguage("compliance","compliance.company.selectDocumentType")}}
                                    </option>

                                    <option
                                    v-for='(listDocumentType,index) in dataAccount.documents.filter(typeCode => typeCode.accountDocumentStatus != "Accepted")'
                                    :key="index" :value="listDocumentType.accountDocumentTypeCode">
                                        
                                        {{ getMyLanguage("compliance","compliance.document-type." + listDocumentType.accountDocumentTypeCode) }}
                                    </option>
                                </select>

                                <label for="LblUploadFile" class="btn-upload" :class='uploadFile.selected'>
                                    <span class="send" v-if="!uploadFile.selected">
                                        {{getMyLanguage("client-space","instructions.receipt.upload.select-file")}}
                                    </span>

                                    <span class="selected" v-if="uploadFile.selected">
                                        {{getMyLanguage("client-space","instructions.receipt.upload.selected-file")}}
                                    </span>

                                    <input type="file" id="LblUploadFile" ref="file" @change="uploadDocument()">
                                </label>

                                <div class="observations-upload noIcon">
                                    <label>
                                        {{getMyLanguage("compliance","compliance.company.observations")}}
                                        <span class="cont">{{dataSendDoc.Observations.length}}/150</span>
                                    </label>

                                    <textarea class="form" v-model="dataSendDoc.Observations" maxlength="150" :placeholder='getMyLanguage("compliance","compliance.placeholder.additional-address")'></textarea>
                                </div>

                                <div class="box-error" v-if="showContent && errorsPost.upload != false">
                                    <h3 class="title-secondary">
                                        {{ getMyLanguage("box-error", "title-error") }}
                                    </h3>

                                    <!-- <p>{{ getMyLanguage("box-error", errorsPost.dataCompliance) }}</p> -->
                                    <div class="cod-error">
                                        Cód.: {{errorsPost.upload}}
                                    </div>
                                </div>

                                <div class="btn-primary btn-disabled" v-if='uploadFile.selected != "fileSelected" || dataSendDoc.accountDocumentTypeCode === ""'>
                                    {{getMyLanguage("client-space","instructions.receipt.send")}}
                                </div>

                                <div class="btn-primary" v-if='uploadFile.selected === "fileSelected" && !errorsPost.upload && !uploadFile.loadUpload && dataSendDoc.accountDocumentTypeCode != ""' @click="uploadDoc()">
                                    {{getMyLanguage("client-space","instructions.receipt.send")}}
                                </div>

                                <div class="btn-primary load" v-if="uploadFile.loadUpload"></div>
                            </div>
                    
                            <!-- MSG DOCS -->
                            <div class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg." + dataAccount.backOfficeStatus)'></div>

                            <div class="continue" v-if='dataAccount.backOfficeStatus == "Accepted"'>
                                <a :href="`/${flagSelected}/account/ubo/company`" class="btn-primary">
                                    {{getMyLanguage("kyc","kyc.shufti-accepted.btn")}}
                                </a>
                            </div>
                            <!-- <div v-if='dataAccount.backOfficeStatus != "Accepted" && !dataAccount.documentsValidated' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg." + dataAccount.backOfficeStatus)'></div>
                            <div v-if='dataAccount.backOfficeStatus === "Pending" && dataAccount.documentsValidated' class="msg Review" v-html='getMyLanguage("compliance","compliance.msg.waitingAccept")'></div> -->
                        </div>
                    </div>



                    <!-- SEND COMPLIANCE -->
                    <!-- <div class="send-compliance" id="#review" v-if='dataAccount.backOfficeStatus === "None" || dataAccount.backOfficeStatus != "Pending"'> -->
                    <div class="send-compliance" id="#review" v-if='dataAccount.documentsValidated && dataAccount.backOfficeStatus != "Accepted"'>
                        <div class="accepts">
                            <div class="form-group-check">
                                <input type="checkbox" id="acceptTermsLG" class="form-check" v-model="accept.terms">
                                
                                <label for="acceptTermsLG" class="form-check-label">
                                    <small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms") }}</small>
                                </label>

                                <a :href="`/${flagSelected}/terms-and-conditions`" target="_blank" class="lnk-terms-group-check">
                                    {{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms.lnk") }}
                                </a>
                            </div>
                            
                            <div class="form-group-check">
                                <input type="checkbox" id="acceptNotify" class="form-check" v-model="accept.notify">
                                
                                <label for="acceptNotify" class="form-check-label">
                                    <small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-notify") }}</small>
                                </label>
                            </div>
                            
                            <div class="form-group-check">
                                <input type="checkbox" id="acceptGDPR" class="form-check" v-model="accept.gdpr">
                                
                                <label for="acceptGDPR" class="form-check-label">
                                    <small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-gdpr") }}</small>
                                </label>
                            </div>
                        </div>

                        <div class="row" v-if="errorsValidate.fullName || errorsValidate.taxNumber">
                            <div class="col-12">
                                <div class="errorsForm">
                                    {{getMyLanguage("compliance","compliance.errorValidateForm")}}
                                </div>
                            </div>
                        </div>

                        <div class="box-error" v-if="showContent && errorsPost.dataCompliance != false">
                            <h3 class="title-secondary">
                                {{ getMyLanguage("box-error", "title-error") }}
                            </h3>

                            <p>{{ getMyLanguage("box-error", errorsPost.dataCompliance) }}</p>
                            
                            <div class="cod-error">
                                Cód.: {{errorsPost.dataCompliance}}
                            </div>
                        </div>

                        <div class="msg-extra" v-if="(!dataAccount.partnersValidated || !dataAccount.documentsValidated) && accept.terms && accept.notify && accept.gdpr">
                            <p v-if="!dataAccount.partnersValidated">
                                <small>*** {{getMyLanguage("compliance","compliance.msg.kyc.incomplete")}}</small>
                            </p>

                            <p v-if="!dataAccount.documentsValidated">
                                <small>*** {{getMyLanguage("compliance","compliance.msg." + dataAccount.backOfficeStatus)}}</small>
                            </p>
                        </div>

                        <div id="btnRegister" v-if="(!dataAccount.partnersValidated || !dataAccount.documentsValidated) || !accept.terms || !accept.notify || !accept.gdpr" class="btn-primary btn-disabled">
                            {{ getMyLanguage("compliance", "compliance.btn-send-review") }}
                        </div>

                        <div id="btnRegister" v-if="(dataAccount.partnersValidated && dataAccount.documentsValidated) && accept.terms && accept.notify && accept.gdpr && !load" @click="validateForm()" class="btn-primary">
                            {{ getMyLanguage("compliance", "compliance.btn-send-review") }}
                        </div>

                        <div class="btn-primary load" v-if="load"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import countriesList from "@/services/listCountries.js";

    import apiInternational from '@/config/apiInternational.js'
    // import apiPlatform from '@/config/apiPlatform.js'
    import moment from 'moment';

    import { handleError } from '@/services/errorHandler.js'
  
    export default {
        components: {
            Header
        },

        data(){
            return{
                showContent: false,
                showCountry: false,
                load: false,

                errorsGet: {
                    dataAccount: false
                },

                errorsPost: {
                    dataCompliance: false,
                    upload: false
                },

                flagSelected: "en",

                dataAccount: [],
                dataDocuments: [],

                dataSendDoc: {
                    accountDocumentTypeCode: '',
                    Observations: ''
                },

                hasPending: '',
                
                dataCountry: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,

                accept: {
                    terms: false,
                    notify: false,
                    gdpr: false
                },

                dataCompliance: {},

                uploadFile: {
                    File: null,
                    selected: false,
                    documentType: "1",
                    loadUpload: false
                },

                modalNoConfirm: {
                    modal: false,
                    load: false,
                    send: false
                },
                noConfirm: {
                    error: "Address error",
                    msg: ""
                },

                errorsValidate: {
                    taxNumber: false,
                    taxNumberIssuerCountry: false
                }
            }
        },
        
        async mounted(){
            await apiInternational.get('/api/v1/international/compliance/company')
            .then(response => {
                this.dataAccount = response.data
                this.dataCompliance.fullName = this.dataAccount.company.fullName
                this.dataCompliance.taxNumber = this.dataAccount.company.taxNumber
                this.dataCompliance.observations = this.dataAccount.company.observations

                // this.hasPending = this.dataModal.documents.filter(typeCode => typeCode.accountDocumentStatusExtra != "Accepted")

                // if ( this.hasPending.length ){
                //     this.dataSendDoc.accountDocumentTypeCode = this.dataAccount.documents.filter(typeCode => typeCode.accountDocumentStatusExtra != "Accepted")[0].accountDocumentTypeCode
                // }
            })
            .catch(error => {
                this.errorsGet.dataAccount = error.response.data
                this.showContent = true

                handleError(error, this.flagSelected, this.$store.state.profile.account_type);
            })

            this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.account.compliance");
                document.head.querySelector('meta[name=description]').content = ''
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },


            // COUNTRIES
            countryTranslate(code){
                return countriesList(code, this.$store.state.SelectLanguage.code)
            },

            ordercountries() {
                this.countriesTranslated = [...this.dataCountry];
                
                this.countriesTranslated.forEach(lang => {
                    lang.translatedCountry = this.countryTranslate(lang.IsoCode);
                });

                this.countriesTranslated.sort((a, b) => {
                    return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
                });
            },


            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            validateForm(){
                if ( this.dataCompliance.fullName === null || this.dataCompliance.fullName === "" ){
                    this.errorsValidate.fullName = true
                } else{
                    this.errorsValidate.fullName = false
                }
                
                if ( this.dataCompliance.taxNumber === null || this.dataCompliance.taxNumber === "" ){
                    this.errorsValidate.taxNumber = true
                } else{
                    this.errorsValidate.taxNumber = false
                }
                
                if ( this.errorsValidate.fullName === false && this.errorsValidate.taxNumber === false ) {
                    this.register()
                }
            },

            register(){
                this.load = true
                const compliance = this.dataCompliance;

                apiInternational.post('/api/v1/international/compliance/company', compliance)
                .then(response => {
                    this.registerId = response.data.id;

                    this.$toast.success(this.getMyLanguage("compliance","compliance.toast.saveData"));
                    setTimeout(this.$toast.clear, 6000)

                    this.updateCompliance()

                    // this.dataAccount = response.data
                    window.location.href = '#top'
                })
                .catch(error => {
                    // this.errorMessage = error.message;

                    // this.errorsGet.dataAccount = error.response.data
                    this.errorsGet.dataAccount = error.response.status

                })
                
                setTimeout(() => {
                    this.load = false
                }, 500);
            },

            async updateCompliance(){
                const updateCompliance = await apiInternational.get('/api/v1/international/compliance/company')
                this.dataAccount = updateCompliance.data
            },

            uploadDocument() {
                this.uploadFile.File = this.$refs.file.files[0];
                console.log(this.uploadFile)

                if ( this.uploadFile.File != null ){
                    this.uploadFile.selected = "fileSelected"
                }
            },

            uploadDoc(){
                this.uploadFile.loadUpload = true
                const formData = new FormData();
                formData.append('File', this.uploadFile.File);
                formData.append('AccountDocumentTypeId', this.dataSendDoc.accountDocumentTypeId);
                formData.append('Type', this.dataSendDoc.accountDocumentTypeCode);
                formData.append('Observations', this.dataSendDoc.Observations);
                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                apiInternational.post("/api/v1/international/compliance/company/document", formData, { headers })
                .then(response => {
                    console.log(response)
                    console.clear()
                    this.dataAccount = response.data

                    this.uploadFile.File = null
                    this.uploadFile.selected = false
                    this.uploadFile.loadUpload = false
                    this.dataSendDoc.Observations = ""

                    this.$toast.success(this.getMyLanguage("compliance","compliance.toast.sendDoc"));
                    setTimeout(this.$toast.clear, 6000)

                    if ( this.dataAccount.documentsValidated ){
                        window.location.href = '#Docs'
                        this.uploadFile.loadUpload = false
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.errorsPost.upload = "error upload"

                    setTimeout(() => {
                        this.errorsPost.upload = false
                        this.uploadFile.loadUpload = false
                    }, 10000);
                })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                    this.ordercountries()
                }
            },
            'this.$store.state.allCountries': {
                immediate: true,
                handler() {
                    setTimeout(() => {
                        this.dataCountry = this.$store.state.allCountries
                        this.showCountry = true
                    }, 1500);
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.compliance");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-compliance.scss" lang="scss" scoped />