<template>
    <div class="auth-kyc">
        <div class="load load-200" v-if="!showContent"></div>

        <div class="container" v-if="showContent && errorsGet.dataAuthKYC != false">
            <div class="box-error">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
            </div>
        </div>

        <!-- IF SUMSUB -->
        <!-- <div class="container" v-if="showContent && KycSumSub && !errorsGet.dataAuthKYC"> -->
        <div class="container">
            <div id="sumsub-websdk-container"></div>
        </div>

        <!-- IF SHUFTI -->
        <div class="container" v-if="showContent && kycShuftiPro && !errorsGet.dataAuthKYC">
            <iframe :src='dataAuthKYC.accessToken' allow="camera" id="Shuftipro-iframe" frameborder="0" class="ShuftiproIframe"></iframe>
        </div>
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import snsWebSdk from '@sumsub/websdk';
    import apiPlatform from '@/config/apiPlatform.js'

    export default {
        props: {
            dataSource: {
                type: Object,
                required: true
            }
        },

        data(){
            return{
                flagSelected: "en",
                showContent: false,
                KycSumSub: false,
                kycShuftiPro: false,

                dataAuthKYC: {},

                dataTokenPost: {
                    check_type: "",
                    signature: ""
                },

                errorsGet: {
                    dataAuthKYC: false
                }
            }
        },

        methods: {
            getMyLanguage(group, code){
               return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            async loadToken(){
                this.dataTokenPost.check_type = this.dataSource.check_type
                this.dataTokenPost.signature = this.dataSource.signature
                
                if ( this.dataSource.behalf_of_account_id ){
                    this.dataTokenPost.behalf_of_account_id = this.dataSource.behalf_of_account_id
                }

                // await apiPlatform.post(`/api/v2/platform/compliance/sumsub/token/${this.dataSource.account_id}`, {"check_type": this.dataSource.check_type, "signature": this.dataSource.signature})
                await apiPlatform.post(`/api/v2/platform/compliance/sumsub/token/${this.dataSource.account_id}`, this.dataTokenPost)
                .then(response => {
                    this.dataAuthKYC = response.data
                    this.showContent = true
                    this.KycSumSub = true

                    this.launchWebSdk(this.dataAuthKYC.access_token, this.dataAuthKYC.email, this.dataAuthKYC.phone_number, this.flagSelected)

                    // if ( this.dataAuthKYC.provider === "ShuftiPro" ){
                    //     this.kycShuftiPro = true
                    // } else{
                    //     this.launchWebSdk(this.dataAuthKYC.accessToken, this.dataAuthKYC.applicantEmail, this.dataAuthKYC.applicantPhoneNumber, this.dataAuthKYC.language)
                    //     this.KycSumSub = true
                    // }
                    
                })
                .catch(error => {
                    console.log(error)
                    this.errorsGet.dataAuthKYC = true
                    this.showContent = true
                })
            },
            
            launchWebSdk(accessToken, applicantEmail, applicantPhone, applicantLanguage) {
                let snsWebSdkInstance = snsWebSdk
                    .init(accessToken, () => this.getNewAccessToken())
                    .withConf({
                        lang: applicantLanguage,
                        email: applicantEmail,
                        phone: applicantPhone,
                    })
                    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
                    .on('idCheck.onStepCompleted', (payload) => {
                        console.log('onStepCompleted', payload)
                    })
                    .on('idCheck.onError', (error) => {
                        console.log('onError', error)
                    })
                    .onMessage((type, payload) => {
                        console.log("onMessage", type, payload);

                        if ( type == 'idCheck.actionStatus' ){
                            if (payload.reviewResult.reviewAnswer === "GREEN" && payload.reviewStatus === "completed"){
                                this.$emit('statusChange', 'success');
                            } else{
                                this.$emit('statusChange', 'retry');
                            }
                        }
                    })

                    .build();

                snsWebSdkInstance.launch('#sumsub-websdk-container')
            },

            getNewAccessToken() {
                return Promise.resolve(this.dataAuthKYC.accessToken)
            },
        },

        watch: {
            'dataSource': {
                handler(){
                    setTimeout(() => {
                        
                        this.loadToken()
                    }, 500);
                },
                immediate: true,
                deep: true
            },
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.flagSelected = this.$store.state.SelectLanguage.code
                    this.getMyLanguage();
                }
            }
        }
    }
</script>