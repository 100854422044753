import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/Home.vue'
import Compliance from '../views/Account/Compliance.vue'
import ComplianceCompany from '../views/Account/ComplianceCompany.vue'
import ComplianceSuccess from '../views/Account/ComplianceSuccess.vue'
import UboPerson from '../views/Account/UboPerson.vue'
import UboCompany from '../views/Account/UboCompany.vue'
import KYC from '../views/Account/KYC.vue'
import ShuftiProResults from '../views/Account/ShuftiProResults.vue'
import Pending from '../views/Account/Pending.vue'
import Login from '../views/Account/Login.vue'
import Consent from '../views/Account/Consent.vue'
import TokenAuth from '../views/Account/TokenAuth.vue'
import ClientSpace from '../views/ClientSpace/ClientSpace.vue'
import ClientSpacePurchase from '../views/ClientSpace/ClientSpacePurchase.vue'
import ClientSpacePurchaseError from '../views/ClientSpace/ClientSpacePurchaseError.vue'
import ClientSpaceWallets from '../views/ClientSpace/ClientSpaceWallets.vue'
import ClientSpaceWalletStake from '../views/ClientSpace/ClientSpaceWalletStake.vue'
import ClientSpaceSuccess from '../views/ClientSpace/ClientSpaceSuccess.vue'
import ClientSpaceSuccessId from '../views/ClientSpace/ClientSpaceSuccessId.vue'
import ClientSpaceInvoice from '../views/ClientSpace/ClientSpaceInvoice.vue'
import ClientSpaceAgreement from '../views/ClientSpace/ClientSpaceAgreement.vue'
import ClientSpaceInvites from '../views/ClientSpace/ClientSpaceInvites.vue'
import ClientSpaceBecomeSeller from '../views/ClientSpace/ClientSpaceBecomeSeller.vue'
import Support from '../views/Support/Support.vue'

import Ico from '../views/Ico/Ico.vue'

import Privacy from '../views/Privacy/Privacy.vue'
import TermsConditions from '../views/TermsConditions/TermsConditions.vue'
import CompanyRegistration from '../views/CompanyRegistration/CompanyRegistration.vue'
import NotFound from '../views/NotFound/NotFound.vue'

const routes = [
  { path: '/', name: 'redirect', redirect: '/en/', component: Home },
  { path: '/:lang/', name: 'Home', component: Home },
  { path: '/:lang/account/compliance/', name: 'Compliance', component: Compliance },
  { path: '/:lang/account/compliance-company/', name: 'ComplianceCompany', component: ComplianceCompany },
  { path: '/:lang/account/compliance-success/', name: 'ComplianceSuccess', component: ComplianceSuccess },
  { path: '/:lang/account/ubo/person', name: 'UboPerson', component: UboPerson },
  { path: '/:lang/account/ubo/company', name: 'UboCompany', component: UboCompany },
  { path: '/:lang/account/consent/', name: 'Consent', component: Consent },
  { path: '/:lang/account/token-auth/', name: 'TokenAuth', component: TokenAuth },
  { path: '/:lang/account/kyc/', name: 'KYC', component: KYC },
  { path: '/:lang/account/shufti-results/', name: 'ShuftiProResults', component: ShuftiProResults },
  { path: '/:lang/account/pending/', name: 'Pending', component: Pending },
  { path: '/:lang/account/login', name: 'Login', component: Login },
  { path: '/:lang/client-space/', name: 'ClientSpace', component: ClientSpace },
  { path: '/:lang/client-space/purchase/', name: 'ClientSpacePurchase', component: ClientSpacePurchase },
  { path: '/:lang/client-space/purchase-error/', name: 'ClientSpacePurchaseError', component: ClientSpacePurchaseError },
  { path: '/:lang/client-space/wallets/', name: 'ClientSpaceWallets', component: ClientSpaceWallets },
  { path: '/:lang/client-space/wallet-stake/', name: 'ClientSpaceWalletStake', component: ClientSpaceWalletStake },
  { path: '/:lang/client-space/invoice/:registerId', name: 'ClientSpaceInvoice', component: ClientSpaceInvoice },
  { path: '/:lang/client-space/agreement/:registerId', name: 'ClientSpaceAgreement', component: ClientSpaceAgreement },
  { path: '/:lang/client-space/invites', name: 'ClientSpaceInvites', component: ClientSpaceInvites },
  { path: '/:lang/client-space/become-seller', name: 'ClientSpaceBecomeSeller', component: ClientSpaceBecomeSeller },
  { path: '/:lang/payment-success/', name: 'ClientSpaceSuccess', component: ClientSpaceSuccess },
  { path: '/:lang/payment-success/:registerId', name: 'ClientSpaceSuccessId', component: ClientSpaceSuccessId },
  { path: '/:lang/support/', name: 'Support', component: Support },
  
  { path: '/:lang/ico/', name: 'Ico', component: Ico },
  
  { path: '/:lang/privacy', name: 'Privacy', component: Privacy },
  { path: '/:lang/terms-and-conditions', name: 'TermsConditions', component: TermsConditions },
  { path: '/:lang/company-registration', name: 'CompanyRegistration', component: CompanyRegistration },
  { path: '/:lang/:catchAll(.*)', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
